import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'

export const SideBarNavBlockWrap = styled.div`
  width: 100%;
`

export default ({
  children,
  slug,
  className,
  navList,
  activeSideNav,
  setSideNavActive,
}) => {
  const [ref, inView, entry] = useInView({
    /* Offset */
    rootMargin: '-50px 0px',
  })

  useEffect(() => {
    if (!entry) return

    const targetSlug = entry.target.dataset.slug
    const targetIndex = navList.indexOf(targetSlug)
    const activeIndex = navList.indexOf(activeSideNav)

    if (activeIndex > targetIndex && inView) {
      const previousIndex = targetIndex
      setSideNavActive(navList[previousIndex])
    } else if (activeIndex === targetIndex && !inView) {
      let nextIndex = targetIndex + 1

      if (navList.length === nextIndex) {
        nextIndex = targetIndex
      }
      setSideNavActive(navList[nextIndex])
    }
  }, [inView])

  return (
    <SideBarNavBlockWrap
      id={slug}
      ref={ref}
      className={className}
      data-slug={slug}
    >
      {children}
    </SideBarNavBlockWrap>
  )
}
