import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { H3Sizes, H4Sizes } from '@styles/Typography'
import { useInView } from 'react-intersection-observer'
import { camelCase } from '@utils/strings'
import SideBarNav from '@components/SideBarNav'
import SideBarNavBlockWrap from '@components/SideBarNav/SideBarNavBlockWrap'
import SideBarNavItem from '@components/SideBarNav/SideBarNavItem'
import TimeBlockDefault from './TimeBlockDefault'
import TimeBlockFeaturedHeading from './TimeBlockFeaturedHeading'
import TimeBlockFeaturedMedia from './TimeBlockFeaturedMedia'
import TimeBlockContainer from './TimeBlockContainer'

export const TimeBlocks = styled.div`
  ${box({
    spacing: 'top',
    multiplier: 1,
    type: 'margin',
  })};
  ${box({
    spacing: 'x',
  })};

  > .inner {
    position: relative;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: ${tokens.get('maxWidth.wide')};
    margin-left: auto;
    margin-right: auto;
  }

  .time-blocks {
    ${box({
      spacing: 'top',
      multiplier: -0.5,
      type: 'margin',
    })};
    ${box({
      spacing: 'bottom',
    })};

    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
    padding-left: ${spacing(6)};
    padding-right: ${spacing(6)};
    margin-left: ${spacing(-2)};
    margin-right: ${spacing(-2)};

    ${media.greaterThan('s')`
      margin-left: ${spacing(-4)};
      margin-right: ${spacing(-4)};
    `}

    ${media.greaterThan('l')`
      margin-left: ${spacing(-6)};
      margin-right: ${spacing(-6)};
    `}
  }
`

const filterByDecade = (timeBlocks) => {
  const keys = []
  const blocksByDecade = {}

  timeBlocks.forEach((block) => {
    const { date: dateText } = block
    const date = dateText.replace(/^\D+/g, '').split('-')[0]
    const key = `${date.slice(0, -1)}0s`
    keys.push(key)

    if (key in blocksByDecade) {
      blocksByDecade[key].push(block)
    } else {
      blocksByDecade[key] = [block]
    }
  })

  return [blocksByDecade, [...new Set(keys)]]
}

export default ({ timeBlocks, className }) => {
  let hasLane = []
  let count = -1
  let isFirst = false

  const [blocksByDecade, navDates] = filterByDecade(timeBlocks)
  const [activeSideNav, setSideNavActive] = useState(navDates[0])

  return (
    <TimeBlocks className={className}>
      <div className="inner">
        <div className="time-blocks">
          {blocksByDecade &&
            Object.keys(blocksByDecade).map((key) => {
              return (
                <SideBarNavBlockWrap
                  key={key}
                  slug={key}
                  navList={navDates}
                  className="decade"
                  activeSideNav={activeSideNav}
                  setSideNavActive={setSideNavActive}
                >
                  {blocksByDecade[key] &&
                    blocksByDecade[key].map(
                      (
                        {
                          __typename,
                          lane,
                          heading,
                          date,
                          content,
                          caption,
                          media,
                        },
                        index
                      ) => {
                        if (!hasLane.includes(lane)) {
                          hasLane.push(lane)
                          isFirst = true
                        } else {
                          isFirst = false
                        }
                        const layout = camelCase(
                          __typename.split('TimeBlocks_').slice(-1)[0]
                        )
                        count++

                        return (
                          <TimeBlockContainer
                            key={index}
                            lane={lane}
                            isFirst={isFirst}
                            count={count}
                            layout={layout}
                          >
                            {
                              {
                                featuredimage: (
                                  <TimeBlockFeaturedMedia
                                    media={media}
                                    caption={caption}
                                    date={date}
                                    lane={lane}
                                  />
                                ),
                                featuredheading: (
                                  <TimeBlockFeaturedHeading
                                    heading={heading}
                                    date={date}
                                  />
                                ),
                                default: (
                                  <TimeBlockDefault
                                    date={date}
                                    heading={heading}
                                    content={content}
                                    media={media}
                                    lane={lane}
                                  />
                                ),
                              }[layout]
                            }
                          </TimeBlockContainer>
                        )
                      }
                    )}
                </SideBarNavBlockWrap>
              )
            })}
        </div>
        <SideBarNav>
          {navDates.map((date, index) => {
            return (
              <SideBarNavItem
                key={index}
                slug={date}
                name={date}
                isActive={activeSideNav === date}
              />
            )
          })}
        </SideBarNav>
      </div>
    </TimeBlocks>
  )
}
