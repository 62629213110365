import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { ThemeContext } from '@layout/Layout'
import { BlogH1Sizes, H2 } from '@styles/Typography'
import { ReactComponent as Icon1 } from '@icons/hops-sketch.svg'
import { ReactComponent as Icon2 } from '@icons/leaf-1-sketch.svg'
import { ReactComponent as Icon3 } from '@icons/leaf-2-sketch.svg'

export const TimeBlockContainer = styled.div`
  ${box({
    spacing: 'top',
    multiplier: 0.5,
  })};
  width: 100%;

  .inner {
    position: relative;
    max-width: ${styledMap('lane', {
      1: tokens.get('maxWidth.narrow'),
      default: tokens.get('maxWidth.tiny'),
    })};

    margin: ${styledMap('lane', {
      1: '0 auto 0 0',
      2: '0 auto',
      3: '0 0 0 auto',
      default: '',
    })};
  }

  .svg {
    position: absolute;

    right: 100%;
    margin-right: ${spacing(2)};
    width: ${spacing(3)};
    height: ${spacing(3)};

    ${styledMap('layout', {
      featuredimage: css`
        top: 0;
      `,
      default: css`
        top: ${spacing(0.5)};

        ${media.greaterThan('s')`
          top: ${spacing()};
        `}
      `,
    })};
  }

  .border-down {
    position: absolute;
    top: ${spacing(2)};
    right: 100%;
    margin-right: ${spacing(3)};
    width: 1px;
    transform: translateX(-50%);
    height: 99999px;
    background: ${styledMap('theme', {
      dark: tokens.color('white', '01'),
      default: tokens.color('black', '01'),
    })};
  }
`
const Icons = [Icon1, Icon2, Icon3]

export default ({ children, lane, className, isFirst, count, layout }) => {
  const { theme } = useContext(ThemeContext)
  const Icon = Icons[count % 3]
  return (
    <TimeBlockContainer
      lane={lane}
      className={className}
      theme={theme}
      layout={layout}
    >
      <div className="inner">
        {isFirst && <div className="border-down" />}
        <div className="svg">
          <Icon />
        </div>
        {children}
      </div>
    </TimeBlockContainer>
  )
}
