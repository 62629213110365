import React from 'react'
import Image from '@elements/Image'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { H2, H3, LargeBoldHeadingSizes, BlogH2Sizes } from '@styles/Typography'

export const TimeBlockFeaturedHeading = styled.div`
  .date {
    ${LargeBoldHeadingSizes}
  }

  .heading {
    ${BlogH2Sizes}
  }
`

export default ({ heading, date, className }) => {
  return (
    <TimeBlockFeaturedHeading className={className}>
      <H2 className="date">{date}</H2>
      <H3 className="heading">{heading}</H3>
    </TimeBlockFeaturedHeading>
  )
}
