import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { H3Sizes, H4Sizes } from '@styles/Typography'
import { spacing, tokens, media } from '@tokens'
import { Link as ScrollLink } from 'react-scroll'

export const SideBarNavItem = styled.li`
  ${H4Sizes};
  list-style: none;
  margin-top: ${spacing(2)};
  font-weight: ${tokens.get('type.fontWeight.bold')};

  &:first-child {
    margin-top: 0;
  }

  ${media.greaterThan('l')`
    ${H3Sizes};
    margin-top: ${spacing(3)};
  `}

  a {
    cursor: pointer;
    white-space: nowrap;
    transition: opacity 125ms linear;
    opacity: ${styledMap('isActive', {
      true: 1,
      default: 0.3,
    })};
  }
`

export default ({ slug, name, isActive }) => {
  return (
    <SideBarNavItem key={slug} slug={slug} isActive={isActive}>
      <ScrollLink to={slug} duration={1200} smooth>
        {name}
      </ScrollLink>
    </SideBarNavItem>
  )
}
