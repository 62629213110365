import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { BlogH1Sizes, H2 } from '@styles/Typography'
import HeadingWithMedia from '@blocks/HeadingWithMedia'
import TextContent from '@elements/TextContent'

export const TimeBlockFeaturedMedia = styled.div`
  .image-container {
    position: relative;
    width: 100%;
    padding-top: ${spacing(3)};

    .image {
      margin-left: ${spacing(-6)};
      margin-right: ${spacing(-6)};
    }

    .image--portrait {
      ${media.greaterThan('s')`
        max-width: 75%;
      `}
    }
  }

  .date {
    ${BlogH1Sizes}
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: ${tokens.z('content')};
  }

  .summary {
    margin-bottom: 0;
    margin-left: 0;
  }
`

export default ({ className, media, lane, caption, date }) => {
  return (
    <TimeBlockFeaturedMedia className={className}>
      <TextContent
        className="summary"
        selectedFields={['content']}
        content={caption}
      />
      <div className="image-container">
        <HeadingWithMedia
          heading={date}
          gradient="top"
          media={media}
          objectFit="contain"
          objectPosition="0% 0%"
        />
      </div>
    </TimeBlockFeaturedMedia>
  )
}
