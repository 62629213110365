import React from 'react'
import styled, { css } from 'styled-components'
import styledMap from 'styled-map'
import { tokens, media, spacing, box } from '@tokens'
import { H2, LargeBoldHeadingSizes } from '@styles/Typography'
import Heading from '@elements/Heading'
import TextContent from '@elements/TextContent'
import MediaItem from '@blocks/MediaItem'

export const TimeBlockDefault = styled.div`
  .heading {
    ${LargeBoldHeadingSizes}
    margin-bottom: ${spacing(2)};
  }

  .media-item {
    margin-top: ${spacing(2)};
  }

  .image--portrait {
    ${media.greaterThan('s')`
      max-width: 75%;
    `}
  }
`

export default ({ date, content, media, lane, className }) => {
  return (
    <TimeBlockDefault className={className}>
      <Heading heading={date} />
      <TextContent content={content} />
      <MediaItem className="media-item" media={media} />
    </TimeBlockDefault>
  )
}
