import React from 'react'
import styled from 'styled-components'
import { getBoxSpacingForProperty } from '@tokens/spacing'
import { spacing, tokens, media, box } from '@tokens'

export const SideBarNav = styled.ul`
  ${getBoxSpacingForProperty('y', {
    property: 'top',
    multiplier: 1,
  })};
  ${box({
    spacing: 'bottom',
  })}
  flex: 0 0 auto;
  position: sticky;
  margin-left: ${spacing(8)};

  ${media.greaterThan('l')`
    margin-left: ${spacing(10)};
  `}

  ${media.lessThan('m')`
    display: none;
  `}
`

export default ({ className, children }) => {
  return <SideBarNav className={className}>{children}</SideBarNav>
}
